<script setup lang="ts">
import type { PopulatedInstrument, PopulatedPack } from '~~/types'

const props = defineProps<{
  instrument?: PopulatedInstrument
  pack?: PopulatedPack
}>()

const videos = computed(() => {
  const { getInstrumentAndPackVideos, getRelatedVideos, getCatalogChapterVideos } =
    useVideoDataTransformer()
  if (props.instrument) {
    return [...getInstrumentAndPackVideos(props.instrument), ...getRelatedVideos(props.instrument)]
  } else if (props.pack && props.pack.videos) {
    return getCatalogChapterVideos(props.pack.videos)
  }
  return []
})
</script>

<template>
  <div>
    <div
      v-if="videos.length"
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <hr class="dvn-videos-divider dvn-videos-divider-left" />
      <span class="dvn-videos-divider-text">{{ $t('Related videos') }} </span>
      <hr class="dvn-videos-divider dvn-videos-divider-right" />
    </div>

    <CatalogInstrumentVideoSlider :videos="videos" />
  </div>
</template>

<style lang="css" scoped>
/* VIDEO DIVIDER */
.dvn-videos-divider {
  border: 1px solid var(--dvn-color-primary);
  border-radius: 5px;
  width: 100%;
}
.dvn-videos-divider-right {
  margin-right: 1em;
}
.dvn-videos-divider-left {
  margin-left: 1em;
}

.dvn-videos-divider-text {
  white-space: nowrap;
  padding: 0 1em;
  font-size: 0.8em;
  font-weight: 500;
  color: var(--dvn-color-primary);
}
</style>
