import type { InstrumentOrPackVideo, VideoData, PopulatedInstrument } from '~~/types'

export function useVideoDataTransformer() {
  function getPreviousChapterData(video: InstrumentOrPackVideo) {
    if (!video.startAt) throw new Error('startAt is required')
    if (!video.chapters) throw new Error('chapters is required')
    const sortedChapters = video.chapters.sort((a, b) => a.time - b.time)
    let chapterImage = ''
    let chapterTitle = ''
    for (const chapter of sortedChapters) {
      if (chapter.time < video.startAt) {
        chapterImage = chapter.thumbnails.default.url
        chapterTitle = chapter.title
      }
    }
    return {
      type: video.type as 'video',
      id: video.videoId as string,
      url: `${video.url}?t=${video.startAt}`,
      title: chapterTitle,
      image: chapterImage,
      time: video.startAt,
    }
  }

  function getCatalogChapterVideos(videos: InstrumentOrPackVideo[]): VideoData[] {
    const r: VideoData[] = []
    if (videos) {
      for (const video of videos) {
        // playlists
        if (video.type === 'playlist') {
          r.push({
            source: 'catalog',
            type: video.type,
            id: video.playlistId as string,
            url: video.url,
            title: video.title,
            image: video.thumbnails.default.url,
          })
        }
        // videos
        if (video.type === 'video') {
          if (video.startAt && video.startAt > 0) {
            // videos with startAt time
            if (video.chapters && video.chapters.length) {
              r.push({ ...getPreviousChapterData(video), source: 'catalog' })
            } else {
              r.push({
                source: 'catalog',
                type: video.type,
                id: video.videoId as string,
                url: `${video.url}?t=${video.startAt}`,
                title: video.title,
                image: video.thumbnails.default.url,
                time: video.startAt,
              })
            }
          } else if (video.chapters && video.chapters.length) {
            // populate chapters
            for (const chapter of video.chapters) {
              r.push({
                source: 'catalog',
                type: video.type,
                id: video.videoId as string,
                url: `${video.url}?t=${chapter.time}`,
                title: chapter.title,
                image: chapter.thumbnails.default.url,
                time: chapter.time,
              })
            }
          } else {
            r.push({
              source: 'catalog',
              type: video.type,
              id: video.videoId as string,
              url: video.url,
              title: video.title,
              image: video.thumbnails.default.url,
            })
          }
        }
      }
    }
    return r
  }

  function getInstrumentVideos(instrument: PopulatedInstrument): VideoData[] {
    return getCatalogChapterVideos(instrument.videos as InstrumentOrPackVideo[])
  }

  function getPackVideos(instrument: PopulatedInstrument): VideoData[] {
    return getCatalogChapterVideos(instrument.pack_id.videos as InstrumentOrPackVideo[])
  }

  function getInstrumentAndPackVideos(instrument: PopulatedInstrument): VideoData[] {
    const instrumentVideos = getInstrumentVideos(instrument)
    const packVideos = getPackVideos(instrument)
    let videos: VideoData[] = [...instrumentVideos, ...packVideos]
    videos = [...new Set(videos)] // remove duplicates
    return videos
  }

  function getRelatedVideos(instrument: PopulatedInstrument): VideoData[] {
    let r: VideoData[] = []
    if (instrument.relatedVideos) {
      r = instrument.relatedVideos.map((video) => {
        return {
          source: 'related',
          type: 'video',
          id: video.videoId,
          url: video.url,
          title: video.chapterTitle ? video.chapterTitle : video.videoTitle,
          image: video.thumbnail.url,
          time: video.startAt,
        }
      })
    }
    return r
  }

  return {
    getInstrumentVideos,
    getPackVideos,
    getInstrumentAndPackVideos,
    getRelatedVideos,
    getCatalogChapterVideos,
  }
}
