<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { VideoData } from '~~/types'

const props = defineProps<{
  videos: VideoData[]
}>()

// video slider
const MAX_VIDEOS = 20
const maxVideos = computed(() => {
  return props.videos.slice(0, MAX_VIDEOS)
})

const { smAndDown } = useDisplay()

const width = computed(() => {
  return smAndDown.value ? `28em` : `52em`
})
</script>

<template>
  <VSheet
    v-if="props.videos.length"
    :max-width="width"
    class="mx-auto no-selection dvn-video-sheet"
  >
    <VSlideGroup show-arrows style="max-width: 100vw">
      <VSlideGroupItem v-for="(item, index) in maxVideos" :key="`video-${index}`">
        <CatalogInstrumentVideoThumbnail :video="item" />
      </VSlideGroupItem>
    </VSlideGroup>
  </VSheet>
</template>

<style lang="css" scoped>
.dvn-video-sheet {
  background-color: transparent;
}
</style>
