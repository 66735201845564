<script setup lang="ts">
import type { VideoData } from '~~/types'

const props = defineProps<{
  video: VideoData
}>()

const videoPlayer = useVideoPlayer()
</script>

<template>
  <ClientOnly>
    <VCard
      v-if="props.video"
      class="ma-1 dvn-video-thumbnail"
      :class="{ 'dvn-video-thumbnail-catalog': props.video.source === 'catalog' }"
      height="4em"
      @click="videoPlayer.selectedVideo = props.video"
    >
      <VImg
        :src="props.video.image"
        :alt="props.video.title"
        width="6em"
        aspect-ratio="4/3"
        cover
      />
      <div class="dvn-video-thumbnail-play-icon-center">
        <VIcon icon="fa-regular:play-circle" class="dvn-video-thumbnail-play-icon" />
      </div>
      <VTooltip activator="parent" location="top" :text="props.video.title" />
    </VCard>
  </ClientOnly>
</template>

<style lang="css" scoped>
/* TOOLTIPS */
.v-tooltip > .v-overlay__content {
  background-color: rgb(var(--v-theme-on-surface));
  color: rgb(var(--v-theme-surface));
  border-radius: 1em;
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.6;
  display: inline-block;
  padding: 0.3em 0.6em;
  text-transform: initial;
  width: auto;
  opacity: 1;
  pointer-events: none;
  transition-property: opacity, transform;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 1);
}

/* .v-tooltip > .v-overlay__content:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid rgb(var(--v-theme-on-surface));
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
} */

/* VIDEO IMAGES */
div.dvn-video-thumbnail {
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 0.3em;
  background-color: rgba(0, 0, 0, 0);
}

div.dvn-video-thumbnail div:hover {
  background-color: rgba(0, 0, 0, 0.35);
  cursor: pointer;
}

.dvn-video-thumbnail-catalog {
  border: 2px solid var(--dvn-color-primary) !important;
}

.dvn-video-thumbnail-play-icon-center {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: background-color var(--dvn-transition-time) var(--dvn-transition-mode);
}

svg.dvn-video-thumbnail-play-icon {
  width: 1.3em;
  height: 1.3em;
  color: rgba(255, 255, 255, 0.5);
}

div.dvn-video-thumbnail-play-icon-center:hover svg.dvn-video-thumbnail-play-icon {
  color: rgba(255, 255, 255, 1);
}
</style>
